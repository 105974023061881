<script setup>
import { animate } from 'motion'

const { animateLerp } = useReactiveLerp()

const props = defineProps({
    animation: {
        type: [Boolean, Array, Object],
        required: false,
        default: () => ({ transform: ['translateY(-250px) translateZ(0)', 'translateY(250px) translateZ(0)'] }),
    },
    optionsDefaults: {
        type: Object,
        required: false,
        default: () => ({
            duration: 1, easing: 'linear', composite: 'accumulate', allowWebkitAcceleration: true,
        }),
    },
    options: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    once: { type: Boolean, required: false, default: false },
    enter: {
        type: [Boolean, Array, Object],
        required: false,
        default: () => ({ opacity: [0, 1], transform: ['translateY(16px) translateZ(0)', 'translateY(0px) translateZ(0)'] }),
    },
    enterOptionsDefaults: {
        type: Object,
        required: false,
        default: () => ({
            duration: 1, easing: [0.14, 0.47, 0.45, 0.94], composite: 'replace', allowWebkitAcceleration: true,
        }),
    },
    enterOptions: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    enterOnce: { type: Boolean, required: false, default: false },
    leave: { type: [Boolean, Array, Object], required: false, default: false },
    leaveOptionsDefaults: {
        type: Object,
        required: false,
        default: () => ({
            duration: 1, easing: [0.14, 0.47, 0.45, 0.94], composite: 'replace', allowWebkitAcceleration: true,
        }),
    },
    leaveOptions: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    leaveOnce: { type: Boolean, required: false, default: false },
    invisibleClass: { type: String, required: false, default: 'parallax-invisible' },
    visibleClass: { type: String, required: false, default: 'parallax-visible' },
    appear: { type: Boolean, required: false, default: true },
    offset: { type: Number, required: false, default: 0 },
    classOnce: { type: Boolean, required: false, default: false },
    lerp: { type: Number, required: false, default: 0 },
    progressOffset: { type: Number, required: false, default: 0 },
    enterLeaveWrapperClasses: { type: String, required: false, default: '' },
})

const attrs = useAttrs()
const emit = defineEmits(['enter', 'leave', 'progress', 'lerp'])

const cssClasses = ref('')
if (props.appear && attrs.class) {
    cssClasses.value = [...attrs.class.split(' '), ...props.invisibleClass.split(' ')].join(' ')
}
const root = ref(null)
const enterLeaveRoot = ref(null)
const parallaxAnimation = ref(null)
const parallaxAnimationOptions = ref({})
const enterAnimation = ref(null)
const enterAnimationOptions = ref({})
const leaveAnimation = ref(null)
const leaveAnimationOptions = ref({})
const done = ref(false)
const enterDone = ref(false)
const leaveDone = ref(false)
const classDone = ref(false)

const updateAnimation = (currentProgress) => {
    emit('progress', currentProgress)
    const activeTime = (parallaxAnimationOptions.value.duration * currentProgress) - props.offset
    parallaxAnimation.value.currentTime = activeTime
    // parallaxAnimation.value.commitStyles();

    if (props.once && parallaxAnimation.value.currentTime >= activeTime) {
        done.value = true
    }
}

const render = (currentProgress) => {
    if (!isServer()) {
        if (parallaxAnimation.value && !done.value) {
            if (props.lerp) {
                animateLerp(currentProgress, props.lerp, updateAnimation)
            }
            else {
                updateAnimation(currentProgress)
            }
        }
    }
}

const enter = () => {
    if (!isServer()) {
        emit('enter', root.value)

        if (enterAnimation.value && (!props.enterOnce || (props.enterOnce && !enterDone.value))) {
            enterAnimation.value.play()

            if (props.enterOnce) {
                enterDone.value = true
            }
        }

        if (!classDone.value) {
            if (props.invisibleClass) {
                root.value.$el.classList.remove(...props.invisibleClass.split(' '))
            }
            if (props.visibleClass) {
                root.value.$el.classList.add(...props.visibleClass.split(' '))
            }

            if (props.classOnce) {
                classDone.value = true
            }
        }
    }
}

const leave = () => {
    if (!isServer()) {
        emit('leave', root.value)

        if (leaveAnimation.value && (!props.leaveOnce || (props.leaveOnce && !leaveDone.value))) {
            leaveAnimation.value.play()

            if (props.leaveOnce) {
                leaveDone.value = true
            }
        }

        if (!classDone.value) {
            if (props.invisibleClass) {
                root.value.$el.classList.add(...props.invisibleClass.split(' '))
            }
            if (props.visibleClass) {
                root.value.$el.classList.remove(...props.visibleClass.split(' '))
            }
        }
    }
}

onMounted(() => {
    if (!isServer()) {
        if (props.enter) {
            enterAnimationOptions.value = { ...props.enterOptionsDefaults, ...props.enterOptions }
            enterAnimation.value = animate(enterLeaveRoot.value, props.enter, toRaw(enterAnimationOptions.value))
            enterAnimation.value.pause()
        }

        if (props.leave) {
            leaveAnimationOptions.value = { ...props.leaveOptionsDefaults, ...props.leaveOptions }
            leaveAnimation.value = animate(enterLeaveRoot.value, props.leave, toRaw(leaveAnimationOptions.value))
            leaveAnimation.value.pause()
        }

        if (props.animation) {
            parallaxAnimationOptions.value = { ...props.optionsDefaults, ...props.options }
            parallaxAnimation.value = animate(root.value.$el, props.animation, toRaw(parallaxAnimationOptions.value))
            parallaxAnimation.value.pause()
        }
    }
})
</script>

<template>
    <VisibleProgress
        ref="root"
        v-slot="{ visible, progress }"
        :class="cssClasses"
        :offset="progressOffset"
        class="backface-hidden will-change-transform"
        v-on:enter="enter"
        v-on:leave="leave"
        v-on:progress="render"
    >
        <div
            ref="enterLeaveRoot"
            class="parallax-enterleave backface-hidden"
            :class="enterLeaveWrapperClasses"
        >
            <slot
                :visible="visible"
                :progress="progress"
            />
        </div>
    </VisibleProgress>
</template>
